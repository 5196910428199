import React, {useState} from 'react';
import arrowLeftWhite from '../images/arrow-left-white.svg';
import {StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const encode = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

const FormContents = ({handleSubmited}) => {
  return (
    <section className="flex flex-col items-center">
      <Formik
        initialValues={{name: '', email: '', message: ''}}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(3, 'Please enter at least 3 characters')
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          email: Yup.string().email('Please enter valid email address').required('Required'),
          message: Yup.string().min(10, 'Please enter at least 10 characters').required('Required'),
        })}
        onSubmit={(values) => {
          handleSubmited(true);
          if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
            window.gtag('event', 'conversion', {
              send_to: 'AW-373340033/m2LBCPPSzJ0DEIHvgrIB',
              event_callback: window.location,
            });
          } else {
            console.log('not sending conversion data.');
          }
          fetch('/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: encode({'form-name': 'request-system', ...values}),
          }).then(() => {
            console.log('Success');
          });
        }}
      >
        {({isSubmitting}) => (
          <Form
            id="request-system"
            name="request-system"
            method="POST"
            data-netlify="true"
            className="w-full"
            data-netlify-honeypot="bot-field"
          >
            <Field type="hidden" name="form-name" value="request-system" />
            <Field type="hidden" name="bot-field" />
            <div className="mb-2 sm:mb-4">
              <label htmlFor="name" className="text-xs font-bold text-left sm:text-sm text-blue">
                Your name*
              </label>
              <Field
                name="name"
                placeholder="name here"
                className="w-full p-2 mt-1 text-sm bg-gray-100 border border-gray-200 rounded text-blue"
              />
              <ErrorMessage component="div" name="name" className="text-xs text-error" />
            </div>

            <div className="mb-2 sm:mb-4">
              <label htmlFor="email" className="text-xs font-bold sm:text-sm text-blue">
                Company email address*
              </label>
              <Field
                name="email"
                placeholder="company email address"
                className="w-full p-2 mt-1 text-sm bg-gray-100 border border-gray-200 rounded text-blue"
              />
              <ErrorMessage component="div" name="email" className="text-xs text-error" />
            </div>

            <div className="mb-10 sm:mb-4">
              <label htmlFor="message" className="text-xs font-bold sm:text-sm text-blue">
                Requested Systems*
              </label>
              <Field
                as="textarea"
                name="message"
                placeholder="Let us know what SaaS systems you are using, or would like to see Trustle add. We will add those to our queue and keep in touch when they become live."
                className="w-full p-2 mt-1 text-sm bg-gray-100 border border-gray-200 rounded resize-none h-28 text-blue"
              />
              <ErrorMessage component="div" name="message" className="text-xs text-error" />
            </div>

            <div className="flex items-center justify-between lg:flex-row">
              <button
                type="submit"
                disabled={isSubmitting}
                className="block w-full px-3 py-1 ml-auto mr-0 text-white transition duration-300 ease-in-out delay-150 rounded-full bg-gradient-to-r from-[#0091FF] via-[#005799] to-[#002761] hover:from-[#4185F4] hover:via-[#00315E] hover:to-[#00315E] sm:py-2 sm:px-7 disabled:bg-gray-300 disabled:opacity-25"
              >
                Send
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

const SubmittedContents = () => {
  return (
    <div className="my-16 md:mb-60 p-5 md:p-14 bg-cover bg-gradient-to-b from-[#002761] to-[#009DDC] rounded-2xl w-full mx-auto">
      <div className="relative text-white">
        <div className="md:absolute z-2 md:right-0 bottom-0 md:bottom-[-140px] max-w-[250px] block mx-auto md:max-w-none relative">
          <StaticImage src="../images/robot-request-submited.png" alt="coming soon systems" quality={100} />
        </div>
        <h2 className="md:leading-[55px] font-bold text-3xl md:text-4xl text-left pb-4">Thanks!</h2>
        <p className="max-w-md mb-8 md:max-w-[260px]">We’ve got that added to our list, and we will keep you posted!</p>
        <Link to="/product/integrations" className="flex items-center mb-4 ">
          <img src={arrowLeftWhite} alt="back arrow" className="w-4 md:w-8" />
          <span className="pl-2 font-bold text-white md:pl-4">See all integrations</span>
        </Link>
      </div>
    </div>
  );
};

const RequestSystemForm = ({handleShowRobot}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  function handleSubmited(value) {
    setFormSubmitted(value);
    handleShowRobot(false);
  }

  return (
    <>
      {formSubmitted ? (
        <SubmittedContents />
      ) : (
        <div className="z-40 flex items-center justify-center">
          <div className="relative z-10 w-full max-w-md px-6 py-10 mb-4 bg-white drop-shadow-2xl">
            <FormContents handleSubmited={handleSubmited} />
          </div>
        </div>
      )}
    </>
  );
};

export default RequestSystemForm;
