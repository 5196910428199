import React, {useState} from 'react';
import {Link} from 'gatsby';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import arrowLeft from '../../../../images/arrow-left.svg';
import RequestSystemForm from '../../../../components/RequestSystemForm';
import {StaticImage} from 'gatsby-plugin-image';
import {graphql, useStaticQuery} from 'gatsby';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {getImage} from 'gatsby-plugin-image';

const Request = () => {
  const [showFormRobot, setShowFormRobot] = useState(true);
  function handleShowRobot(value) {
    setShowFormRobot(value);
  }

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);

  const {backgroundImage} = data;
  const bgImage = convertToBgImage(getImage(backgroundImage));

  return (
    <Layout
      seo={
        <Seo
          title="Request System | Trustle"
          description="We want to hear from you! Let us know what systems you are interested in and we’ll add it to our queue. You’ll be one of the first to know when it’s live!"
        />
      }
    >
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <section className="py-16">
          <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
            <Link to="/product/integrations" className="flex items-center">
              <img src={arrowLeft} alt="back arrow" className="w-4 md:w-8" />
              <span className="pl-2 md:pl-4">See all integrations</span>
            </Link>
          </div>
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
            <SectionIntro
              hero="Contact us"
              title="Request specific systems"
              description="We want to hear from you! Let us know what systems you are interested in and we’ll add it to our queue. You’ll be one of the first to know when it’s live!"
              isPageHeader
            />
          </div>
          <div className="max-w-4xl px-5 pt-0 mx-auto text-white">
            <div className="flex flex-col justify-between md:flex-row">
              <RequestSystemForm handleShowRobot={handleShowRobot} />
              <div className="max-w-[370px]">
                {showFormRobot && (
                  <StaticImage
                    src="../../../../images/systems/systems-request.png"
                    width={370}
                    alt="coming soon systems"
                    quality={100}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default Request;
